<template>
    <div class="mt-8">
        <hooper :settings="hooperSettings" class="h-full">
            <slide v-for="(videos, i) in videos" :key="i" >
                <iframe width="95%" height="315px" class="mx-auto" :src="videos.link" frameborder="0" allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
                </iframe>
            </slide>
            <hooper-navigation slot="hooper-addons"></hooper-navigation>
        </hooper>
    </div>
</template>

<script>
import "hooper/dist/hooper.css";
import { Hooper, Slide, Navigation as HooperNavigation } from "hooper";


export default {
    name: 'YourComponent',
    components: {
        Hooper,
        Slide,
        HooperNavigation,
    },
    data() {
        return {
            hooperSettings: {
                itemsToShow: 1,
                centerMode: true,
                autoPlay: false,
                infiniteScroll: true,
                hoverPause: false,
                wheelControl: false,
                playSpeed: 5000,
                breakpoints: {
                    767: {
                        centerMode: false,
                        itemsToShow: 2,
                    },
                    1000: {
                        itemsToShow: 3,
                        centerMode: false,
                    },
                },
            },
            videos: [
            {
                    link: 'https://www.youtube.com/embed/73Mcd5ir2TU?si=eHIDiX7wXpMDcrF6',
                },
                {
                    link: 'https://youtube.com/embed/ab6sAm9pDxM?si=dBtf0tUcwTmzwZE5',
                },
                {
                    link: 'https://youtube.com/embed/2iRyPtrXIpE?si=I6KXMeQ5M-dCspX9',
                },
                {
                    link: 'https://youtube.com/embed/I9ioDNes7kE?si=dIyDXTtsgvo-f_kX',
                },
                {
                    link: 'https://youtube.com/embed/Nka877IESOU?si=Zj3VECPnBNZki4pR',
                },
                {
                    link: 'https://youtube.com/embed/fTikj_mDYbo?si=yTP0FI54N8UbVvMy',
                },
                {
                    link: 'https://youtube.com/embed/f28HFNZUXs4?si=gGwIH1aEx3Cy2mra',
                },
                {
                    link: 'https://youtube.com/embed/iMqxzo_EB0I?si=YosNPeKdMnm5Wf-E',
                },
            ],
        };
    },
};
</script>